/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import get from "lodash/get"
import { useMedia } from "react-use"
import { findBySlug, renderLineBreak, mediaQueryList } from "../helpers"
import Carousel from "../components/carousel"
import Container from "../components/container"
import Column from "../components/column"
import RichText from "../components/rich-text"
import Hero from "../components/hero"
import Page from "../components/page"
import Row from "../components/row"
import Text from "../components/text"
import CTA from "../components/cta"
import Quote from "../components/design-partner/quote"

import { paramsCheck } from "../functions/functions"
import moment from "moment"

const CareersPage = ({ data }) => {
  const mediaQueryMatch = useMedia(
    mediaQueryList({ mediaPrefix: false })[2],
    false
  )
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "careers-page-blade-pattern-hero",
    })
    paramsCheck()

    return (
      heroBladePatternData && (
        <Hero
          background={{
            type: "image",
            source: {
              mobileImage: heroBladePatternData.mobileImage,
              desktopImage: heroBladePatternData.desktopImage,
            },
          }}
          {...(heroBladePatternData.description && {
            body: heroBladePatternData.description.description,
          })}
          title={heroBladePatternData.title}
          type="secondary"
          textWidth={576}
          verticalAlign="center"
          bodyStyle={{fontSize:17}}
          ctaList={
            get(heroBladePatternData, "ctaTitle", null)
              ? [
                  {
                    type: "secondary",
                    text: get(heroBladePatternData, "ctaTitle", ""),
                    link: get(heroBladePatternData, "ctaDestination", "/"),
                  },
                ]
              : null
          }
        />
      )
    )
  }
  const renderBenefits = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "careers-benefits-module",
    })
    const TitleSecondary = ({ children }) => (
      <Text type="h3" customStyle={{ fontSize: 22, pb: 2 }}>
        {children}
      </Text>
    )
    return (
      bladePatternData && (
        <Container>
          <Row
            customStyle={{
              py: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
            }}
          >
            {bladePatternData.contentCards[0] && (
              <Column
                size={[12, 12, 12, 12, null]}
                customStyle={{
                  justifyContent: "flex-end",
                  minWidth: [null, 400],
                  pb: [4, 4, 4, 4, 0],
                }}
              >
                <Text
                  type="h2"
                  customStyle={{
                    pb: 3,
                    fontSize: [36, 36, 40],
                  }}
                >
                  {bladePatternData.contentCards[0].title}
                </Text>
                <RichText data={bladePatternData.contentCards[0].description} textStyle={{fontSize:17}}/>
              </Column>
            )}
            <Column
              size={[12, 12, 12, 12, null]}
              customStyle={{
                justifyContent: "flex-end",
                pl: [null, null, null, null, 5],
              }}
            >
              {bladePatternData.contentCards[1] && (
                <div>
                  <TitleSecondary>
                    {bladePatternData.contentCards[1].title}
                  </TitleSecondary>
                  <RichText
                    data={bladePatternData.contentCards[1].description}
                    textStyle={{fontSize:17}}
                  />
                </div>
              )}
              {bladePatternData.contentCards[2] && (
                <div>
                  <TitleSecondary>
                    {bladePatternData.contentCards[2].title}
                  </TitleSecondary>
                  <RichText
                    textStyle={{fontSize:17}}
                    data={bladePatternData.contentCards[2].description}
                  />
                </div>
              )}
            </Column>
            <Column
              size={[12, 12, 12, 12, null]}
              customStyle={{
                justifyContent: "flex-end",
                pl: [null, null, null, null, 5],
              }}
            >
              {bladePatternData.contentCards[3] && (
                <div>
                  <TitleSecondary>
                    {bladePatternData.contentCards[3].title}
                  </TitleSecondary>
                  <RichText
                  textStyle={{fontSize:17}}
                    data={bladePatternData.contentCards[3].description}
                  />
                </div>
              )}
              {bladePatternData.contentCards[4] && (
                <div>
                  <TitleSecondary>
                    {bladePatternData.contentCards[4].title}
                  </TitleSecondary>
                  <RichText
                  textStyle={{fontSize:17}}
                    data={bladePatternData.contentCards[4].description}
                  />
                </div>
              )}
            </Column>
            <Column
              size={[0, 0,0, 12, null]}
              customStyle={{
                justifyContent: "flex-start",
                pl: [null, null, null, null, 100],
                pr: 0,
                maxWidth: [180, 180, 180, 180, 300],
              }}
            >
              {/* {bladePatternData.contentCards[5] && (
                <div>
                  <TitleSecondary>
                    {bladePatternData.contentCards[5].title}
                  </TitleSecondary>
                  <RichText
                    data={bladePatternData.contentCards[5].description}
                  />
                </div>
              )} */}
            </Column>
          </Row>
        </Container>
      )
    )
  }
  const renderCarousel = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "careers-carousel-module",
    })
    return (
      bladePatternData.contentCards &&
      bladePatternData.contentCards.length > 0 && (
        <Container
          customStyle={{
            py: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
            px: [0, 0, 0, 0, 0, 100],
          }}
        >
          <Carousel type={"quaternary"}>
            {bladePatternData.contentCards.map(card => (
              <img
                alt=""
                sx={{ width: "100%" }}
                src={card.desktopImage.file.url}
              />
            ))}
          </Carousel>
        </Container>
      )
    )
  }
  const renderJobListings = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "careers-job-listing-module",
    });

    const allphabeticallySortedJobs = data.allContentfulJobListing.nodes.sort((a,b)=>{
      if(a.jobTitle>b.jobTitle) return 1;
      if(a.jobTitle<b.jobTitle) return -1
    })

    // console.log(data.allContentfulJobListing.nodes.sort((a,b)=>{
    //   if(a.jobTitle>b.jobTitle) return 1;
    //   if(a.jobTitle<b.jobTitle) return -1
    // }))

    const renderJobEntry = job => (
      <div sx={{ ":last-of-type .ctas": { pb: 0 }, maxWidth: [null, 550] }}>
        <Text type="h3" customStyle={{ fontSize: 20, pb: 2 }}>
          {job.jobTitle}
        </Text>
        <Text customStyle={{ fontSize: 17}}>{job.teaser.teaser}</Text>
        <div sx={{ pt: "20px", pb: 40 }} className="ctas">
          {/* <CTA
            link={"/jobs/" + job.slug || "/"}
            type="secondary"
            linkType="internal"
            customStyle={{
              width: "calc(50% - 8px)",
              mr: 16,
            }}
          >
            {"View Position"}
          </CTA> */}
          <CTA
            link={"https://recruiting.paylocity.com/recruiting/jobs/All/21f37abf-7cdd-49ff-b8cb-d366274be35b/Plant-Prefab-Inc"}
            type="secondary"
            linkType="external"
            customStyle={{
              width: "calc(50% - 8px)",
              mr: 16,
            }}
          >
            {"Learn More"}
          </CTA>
          {job.url && job.url.url && (
            <CTA
              link={job.url.url}
              type="alt"
              linkType="external"
              customStyle={{
                width: "calc(50% - 8px)",
              }}
            >
              {"Apply Now"}
            </CTA>
          )}
        </div>
      </div>
    )
    return (
      <div
        sx={{
          background: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.md,
        }}
      >
        <Container>
          <Row>
            <Column
              size={[12, 12, 12, null]}
              customStyle={{
                minWidth: [null, null, null, 435],
                maxWidth: [null, null, null, 435],
              }}
            >
              <Text
                type="h2"
                customStyle={{ fontSize: [36, 36, 40], pb: [5, 5, 5, 0] }}
              >
                {mediaQueryMatch
                  ? renderLineBreak(bladePatternData.title)
                  : bladePatternData.title.replace("\\n", "")}
              </Text>
            </Column>
            <Column size={[12, 12, 12, null]}>
              {data.allContentfulJobListing.nodes.length > 0 ? (
                allphabeticallySortedJobs.map(job =>
                  renderJobEntry(job)
                )
              ) : (
                <Text type="h3" customStyle={{ color: "#777" }}>
                  {bladePatternData?.contentCards && bladePatternData?.contentCards[0].title}
                </Text>
              )}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  const renderOurTeamModule = () => {
    const teamGalleryBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "company-team-gallery",
    })

    const renderTeamImage = idx => {
      const imgUrl = get(
        teamGalleryBladePatternData,
        `contentCards[${idx}].desktopImage.file.url`,
        "/"
      )
      return <img src={imgUrl} alt="" />
    }
    if (!teamGalleryBladePatternData) return
    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        
        <Container customStyle={{ pb: 5 }}>
          <Row>
            <Column
              size={[12, 12, 6]}
              customStyle={{
                mb: theme => [
                  theme.spacing.horizontal,
                  theme.spacing.horizontal,
                  0,
                ],
              }}
            >
              <Row customStyle={{ mb: theme => theme.spacing.horizontal }}>
                <Column size={[12]}>{renderTeamImage(0)}</Column>
              </Row>
              <Row>
                <Column size={[6]}>{renderTeamImage(1)}</Column>
                <Column size={[6]}>{renderTeamImage(2)}</Column>
              </Row>
            </Column>
            <Column size={[12, 12, 6]}>
              <Row customStyle={{ mb: theme => theme.spacing.horizontal }}>
                <Column size={[6]}>{renderTeamImage(3)}</Column>
                <Column size={[6]}>{renderTeamImage(4)}</Column>
              </Row>
              <Row>
                <Column size={[12]}>{renderTeamImage(5)}</Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderBenefits()}
      {renderJobListings()}
      {renderOurTeamModule()}
      {renderCarousel()}
      {renderQuote()}
    </Page>
  )
}

export default CareersPage

export const query = graphql`
  query careersPageQuery {
    contentfulPage(slug: { eq: "careers" }) {
      ...PageData
      ...BladePatternData
    }
    allContentfulJobListing {
      nodes {
        slug
        url: childContentfulJobListingUrlTextNode {
          url
        }
        teaser: childContentfulJobListingTeaserTextNode {
          teaser
        }
        jobTitle
        location
      }
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "careers-quotes-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
